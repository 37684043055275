































import { Component, Vue } from "vue-property-decorator";
import { MarketStatus } from "@/core";
import HelpIcon from "vue-material-design-icons/HelpCircleOutline.vue";
import BackIcon from "vue-material-design-icons/KeyboardBackspace.vue";

@Component({
  components: {
    HelpIcon,
    BackIcon,
  },
})
export default class extends Vue {
  get marketIsOpen(): boolean {
    return MarketStatus.open;
  }

  openHelpModal(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("../orders/OrdersHelpModal.vue")
    );
    this.$store.dispatch("modal/show");
  }
}
